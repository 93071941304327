<template v-if="isLunchActive || isPickupActive">
  <v-card
      color="SystemCTA"
      class="mb-3"
      :class="{ 'today-card': isToday(detail.date) }"
      :disabled="containsOnlyDate(detail)"
      outlined
      tile
      @click="goToEditView"
  >
    <v-list class="pt-3 px-2">
      <!-- title -->
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="Text01--text text-subtitle-1 d-flex justify-space-between align-center">
            <div>
              {{ formatDate(detail.date) }}
              <template v-if="isToday(detail.date)">
                <span class="GreyLine--text mx-1">|</span>
                <span class="SystemCTA--text text-subtitle-2">{{ $t('today') }}</span>
              </template>
            </div>
            <div v-if="!isPast(detail.date) && (pickupTimeIsEditable || lunchIsEditable)" class="icon-container">
              <v-icon color="SystemCTA">mdi-pencil</v-icon>
            </div>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <template v-if="!containsOnlyDate(detail)">
        <!-- absence -->
        <v-list-item v-if="isPickupActive && detail.absences.length">
          <v-list-item-avatar size="44">
            <v-icon size="24" :color="isPast(detail.date) ? 'Text03' : 'SystemCTA'">mdi-account-off</v-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-subtitle class="Text02--text">{{ $t('memberAbsent') }}</v-list-item-subtitle>
            <v-list-item-title
                v-for="(absence, index) in detail.absences"
                :key="index"
                class="Text01--text text-subtitle-2"
            >
              {{ getAbsentTextForList(absence, detail.date) }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <template v-if="!isWholeDayAbsence(detail)">
          <v-divider v-if="(showPickup && detail.absences.length)" class="mx-4"/>

          <!-- Pickup -->
          <v-list-item v-if="showPickup">
            <v-list-item-avatar size="44">
              <v-icon size="24" :color="isPast(detail.date) ? 'Text03' : 'SystemCTA'">mdi-clock</v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-subtitle class="Text02--text">{{ $t('memberPickupTime') }}</v-list-item-subtitle>
              <v-list-item-title class="Text01--text text-subtitle-2 d-flex align-center">
                {{ formatPickupTime(getActualPickup(detail)) }}
                <SquareText
                    :shouldDisplay="detail.changed_pickup && detail.standard_pickup_time !== detail.changed_pickup_time"
                    :text="formatPickupTime(detail.standard_pickup_time)"
                />
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-divider v-if="(showPickup && isLunchActive)" class="mx-4"/>

          <!-- lunch -->
          <v-list-item v-if="isLunchActive">
            <v-list-item-avatar size="44">
              <v-icon size="24" :color="isPast(detail.date) ? 'Text03' : 'SystemCTA'" style="border-radius: unset;">
                $fast_food_fill
              </v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-subtitle class="Text02--text">{{ $t('memberLunch') }}</v-list-item-subtitle>
              <v-list-item-title class="Text01--text text-subtitle-2 d-flex align-center">
                {{ formatLunchRequired(getActualLunch(detail)) }}
                <SquareText
                    :shouldDisplay="detail.changed_lunch_required !== null && detail.changed_lunch_required !== detail.standard_lunch_required"
                    :text="formatLunchRequired(detail.standard_lunch_required)"
                />
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
      </template>
    </v-list>
  </v-card>
</template>

<script>
import SquareText from "@/components/pages/MemberPage/components/PickupTimesLunch/SquareText";
import dateUtilityMixin from "@/mixins/dateUtilityMixin";
import pickupLunchMixin from "@/mixins/pickupLunchMixin";
import _ from "lodash";

export default {
  name: "DetailsItem",

  mixins: [ dateUtilityMixin, pickupLunchMixin ],

  components: { SquareText },

  props: {
    detail: {
      type: Object,
      required: true
    },
    isLunchActive: {
      type: Boolean,
      default: false,
    },
    isPickupActive: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    showPickup() {
      return this.isPickupActive && this.getActualPickup(this.detail) !== null;
    }
  },

  methods: {
    goToEditView() {
      const id = this.$route.params.id;
      const date = this.detail.date;

      this.$router.push({ name: 'member.edit.pickup_times_lunch', params: { id, date } }).catch(() => {
      });
    },
    containsOnlyDate(detail) {
      return _.isEqual(_.keys(detail), [ 'date' ]);
    },
  },
};
</script>

<style lang="scss" scoped>
.v-avatar {
  border: 1px solid var(--v-GreyLine-base);
}

.today-card {
  border-left-width: thick;
}

.icon-container {
  border-radius: 50%;
  transition: background-color 0.3s ease;
  cursor: pointer;
  padding: 8px;
}

.icon-container:hover {
  background-color: var(--v-SystemBG-base);
}

.v-card--disabled > :not(.v-card__progress) {
  opacity: initial !important;
}
</style>