import Vue from 'vue';
import Router from 'vue-router';
import VueHead from 'vue-head';

import store from '@/store/index';
import {UserGetters, UserActions, AppType} from '@/store/user';

import LoginPage from '@/components/pages/LoginPage';
import RegisterPage from '@/components/pages/RegisterPage';
import DashboardPage from '@/components/pages/DashboardPage';
import NewsListPage from '@/components/pages/NewsListPage';
import PinnedNewsListPage from '@/components/pages/PinnedNewsListPage';
import NewsItemPage from '@/components/pages/NewsItemPage';
import AnswersPage from '@/components/pages/NewsItemPage/pages/AnswersPage';
import ForgotPasswordPage from '@/components/pages/ForgotPasswordPage';
import ResetPasswordPage from '@/components/pages/ResetPasswordPage';
import EventsListPage from '@/components/pages/EventsListPage';
import ContactsPage from "@/components/pages/ContactsPage";
import ProfileInfoPage from "@/components/pages/AccountSettingsPage/pages/ProfileInfoPage";
import PasswordPage from "@/components/pages/AccountSettingsPage/pages/PasswordPage";
import AppLanguagePage from "@/components/pages/AccountSettingsPage/pages/AppLanguagePage";
import TranslationLanguagePage from "@/components/pages/AccountSettingsPage/pages/TranslationLanguagePage";
import ManageMessengerPage from "@/components/pages/AccountSettingsPage/pages/ManageMessengerPage";
import LegalPage from "@/components/pages/AccountSettingsPage/pages/LegalPage";
import EventPage from "@/components/pages/EventPage";
import NewsTopBarMenu from "@/components/pages/NewsListPage/components/NewsTopBarMenu";
import PinnedNewsTopBarMenu from "@/components/pages/NewsListPage/components/PinnedNewsTopBarMenu";
import EventsTopBarMenu from "@/components/pages/EventsListPage/components/EventsTopBarMenu";
import MemberPage from "@/components/pages/MemberPage";
import MembersPage from "@/components/pages/MembersPage";
import AbsentEntriesListPage from "@/components/pages/AbsentEntriesListPage";
import PickupTimesLunch from "@/components/pages/MemberPage/pages/PickupTimesLunch";
import AdditionalContacts from "@/components/pages/MemberPage/pages/AdditionalContacts";
import FoodPlansPage from "@/components/pages/FoodPlansPage";
import TimetablesPage from "@/components/pages/TimetablesPage";
import HourPlansPage from "@/components/pages/HourPlansPage";
import NotFoundPage from "@/components/pages/NotFoundPage";
import ResetPasswordSuccessPage from "@/components/pages/ResetPasswordSuccessPage";
import NoticeboardListPage from "@/components/pages/NoticeboardListPage";
import {AssociationsActions} from "@/store/association";
import i18next from 'i18next';
import {GlobalActions, GlobalGetters} from '@/store/global';
import {ChatUserActions} from '@/constants';
import EditPickupTimesLunch from "@/components/pages/MemberPage/pages/EditPickupTimesLunch";
import moment from "moment";

Vue.use(Router);
Vue.use(VueHead);

const router = new Router({
    mode: 'history',
    routes: [
        {
            path: '/login',
            name: 'login',
            component: LoginPage,
            meta: {
                imageBackground: true,
                hideHeaderFooter: true
            }
        },
        {
            path: '/forgot-password',
            name: 'forgot_password',
            component: ForgotPasswordPage,
            meta: {
                title: 'forgotPasswordTitle',
                imageBackground: true
            }
        },
        {
            path: '/reset-password/:token',
            name: 'reset_password',
            component: ResetPasswordPage,
            meta: {
                title: 'resetPasswordTitle',
                imageBackground: true
            }
        },
        {
            path: '/reset-password-success',
            name: 'reset_password_success',
            component: ResetPasswordSuccessPage,
            meta: {
                title: 'resetPasswordSuccessTitle',
                imageBackground: true
            }
        },
        {
            path: '/profile',
            name: 'profile_settings',
            component: ProfileInfoPage,
            meta: {
                title: 'accountSettings',
                imageBackground: true
            },
        },
        {
            path: '/profile/password',
            name: 'profile_password',
            component: PasswordPage,
            meta: {
                title: 'accountSettings'
            }
        },
        {
            path: '/profile/app-language',
            name: 'profile_app_language',
            component: AppLanguagePage,
            meta: {
                title: 'accountSettings'
            }
        },
        {
            path: '/profile/translation-language',
            name: 'profile_translation_language',
            component: TranslationLanguagePage,
            meta: {
                title: 'accountSettings'
            },
            beforeEnter(to, from, next) {
                const {inAppTranslatorActive} = store.getters[`user/${UserGetters.AccountConfiguration}`];

                if (!inAppTranslatorActive) {
                    next({name: 'profile_settings'})
                }

                return next();
            }
        },
        {
            path: '/profile/manage-messenger',
            name: 'profile_manage_messenger',
            component: ManageMessengerPage,
            meta: {
                title: 'accountSettings'
            },
            beforeEnter(to, from, next) {
                const {
                    groupChatActive,
                    individualChatActive
                } = store.getters[`user/${UserGetters.AccountConfiguration}`];

                if (!groupChatActive && !individualChatActive) {
                    next({name: 'profile_settings'})
                }

                return next();
            }
        },
        {
            path: '/profile/legal',
            name: 'profile_legal',
            component: LegalPage,
            meta: {
                title: 'accountSettings'
            }
        },
        {
            path: '/register/:token',
            name: 'register',
            component: RegisterPage,
            meta: {
                title: 'registerTitle',
                imageBackground: true
            }
        },
        {
            path: '/',
            name: 'dashboard',
            component: DashboardPage,
            children: [
                {
                    path: 'news',
                    name: 'news',
                    component: NewsListPage,
                    meta: {
                        title: 'newsTitle',
                        showUnreadNewsCount: true,
                        showAdditionalActions: true,
                        topBarComponent: NewsTopBarMenu,
                    }
                },
                {
                    path: 'news/pinned',
                    name: 'news.pinned',
                    component: PinnedNewsListPage,
                    meta: {
                        title: 'pinnedNewsTitle',
                        showAdditionalActions: true,
                        backTo: 'news',
                        topBarComponent: PinnedNewsTopBarMenu,
                    }
                },
                {
                    path: 'news/pinned/:id/:translationLang?',
                    name: 'news.pinned.show',
                    component: NewsItemPage,
                    meta: {
                        title: 'newsDetailsTitle',
                        backTo: 'news.pinned',
                        fillHeight: true,
                    }
                },
                {
                    path: 'news/pinned/:id/answers',
                    name: 'news.pinned.answers',
                    component: AnswersPage,
                    meta: {
                        title: 'answers',
                        backTo: 'news.pinned.show',
                        fillHeight: true,
                    }
                },
                {
                    path: 'news/pinned/:id/answer',
                    name: 'news.pinned.answer',
                    component: AnswersPage,
                    meta: {
                        title: 'answer',
                        backTo: 'news.pinned.show',
                        fillHeight: true,
                    }
                },
                {
                    path: 'news/:id/:translationLang?',
                    name: 'news.show',
                    component: NewsItemPage,
                    meta: {
                        title: 'newsDetailsTitle',
                        backTo: 'news',
                        fillHeight: true,
                    }
                },
                {
                    path: 'news/:id/answers',
                    name: 'news.answers',
                    component: AnswersPage,
                    meta: {
                        title: 'answers',
                        backTo: 'news.show',
                        fillHeight: true,
                    }
                },
                {
                    path: 'news/:id/answer',
                    name: 'news.answer',
                    component: AnswersPage,
                    meta: {
                        title: 'answer',
                        backTo: 'news.show',
                        fillHeight: true,
                    }
                },
                {
                    path: 'noticeboards',
                    name: 'noticeboard',
                    component: NoticeboardListPage,
                    meta: {
                        title: 'noticeboard_title',
                    }
                },
                {
                    path: 'events',
                    name: 'events',
                    component: EventsListPage,
                    meta: {
                        title: 'calendarTitle',
                        showAdditionalActions: true,
                        topBarComponent: EventsTopBarMenu,
                    }
                },
                {
                    path: 'events/:id/:translationLang?',
                    name: 'events.show',
                    component: EventPage,
                    meta: {
                        title: 'eventDetailsTitle',
                        backTo: 'events',
                        fillHeight: true,
                    }
                },
                {
                    path: 'contacts',
                    name: 'contacts',
                    component: ContactsPage,
                    meta: {
                        title: 'contactTitle',
                        imageBackground: true
                    }
                },
                {
                    path: 'members',
                    name: 'members',
                    component: MembersPage,
                    meta: {
                        title: 'childrenSideMenu',
                        imageBackground: true,
                        dynamicTitle: true,
                        expression: (appType) => appType === AppType.Care ? 'residentsTitle' : 'childrenSideMenu',
                    }
                },
                {
                    path: 'member/:id',
                    name: 'member.show',
                    component: MemberPage,
                    meta: {
                        title: 'memberProfile',
                        backTo: 'members',
                        dynamicTitle: true,
                        expression: (appType) => appType === AppType.Care ? 'residentProfile' : 'memberProfile'
                    }
                },
                {
                    path: 'member/:id/absent-entries',
                    name: 'member.show.absent_entries',
                    component: AbsentEntriesListPage,
                    meta: {
                        title: 'memberProfile',
                        backTo: 'members'
                    }
                },
                {
                    path: 'member/:id/pickup-times-lunch',
                    name: 'member.show.pickup_times_lunch',
                    component: PickupTimesLunch,
                    meta: {
                        title: 'memberProfile',
                        backTo: 'members'
                    }
                },
                {
                    path: 'member/:id/pickup-times-lunch/:date',
                    name: 'member.edit.pickup_times_lunch',
                    component: EditPickupTimesLunch,
                    meta: {
                        backTo: 'member.show.pickup_times_lunch',
                    },
                },
                {
                    path: 'member/:id/additional-contacts',
                    name: 'member.show.additional_contacts',
                    component: AdditionalContacts,
                    meta: {
                        title: 'memberProfile',
                        backTo: 'members'
                    }
                },
                {
                    path: 'lunch-plans',
                    name: 'food_plans',
                    component: FoodPlansPage,
                    meta: {
                        title: 'foodPlanTitle',
                        imageBackground: true,
                    }
                },
                {
                    path: 'timetables',
                    name: 'timetables',
                    component: TimetablesPage,
                    meta: {
                        title: 'timetableTitle',
                        imageBackground: true,
                    }
                },
                {
                    path: 'substitution-plans',
                    name: 'hour_plans',
                    component: HourPlansPage,
                    meta: {
                        title: 'hourPlanTitle',
                        imageBackground: true,
                    }
                },
            ]
        },
        {
            path: '/404',
            name: '404',
            component: NotFoundPage,
        },
        {
            path: '*',
            redirect: '/404'
        }
    ]
});

router.onError((err) => {
    const {status} = err.response || {};
    if (status === 401) {
        router.push({name: 'login'});
    }
});

function setIcons() {
    let links = document.querySelectorAll('link[rel="icon"], link[rel="apple-touch-icon"], link[rel="manifest"]');
    let msApplicationConfig = document.querySelector('meta[name="msapplication-config"]');
    const msApplicationContentFilename = msApplicationConfig.content.split('/').slice(-1)[0];

    let prefix = '/default-icons/';

    links.forEach((link) => {
        const iconName = (new URL(link.href)).pathname.split('/').slice(-1)[0];
        link.href = prefix + iconName;
    })
    msApplicationConfig.content = prefix + msApplicationContentFilename;
}

router.beforeResolve(async (to, from, next) => {
    if (to.name === 'member.edit.pickup_times_lunch' && to.params.date) {
        try {
            await store.dispatch(`user/${UserActions.CheckIfAuthenticated}`);
            moment.locale(i18next.language);
            to.meta.title = moment(to.params.date, 'YYYY-MM-DD').format(i18next.t('titleDateFormat'));
        } catch (e) {
            await store.dispatch(`user/${UserActions.Logout}`);
            next({name: 'login'});
        }
    }
    next();
})

router.beforeEach(async (_to, _from, next) => {
    if (navigator?.onLine) {
        next();
    } else {
        Vue.toasted.error(i18next.t('loginOfflineMessage'));
    }

    if (_to.path.includes('chat')) {
        _to.meta.title = 'messengerSideMenu';
    }

    try {
        await store.dispatch(`user/${UserActions.CheckIfAuthenticated}`);
    } catch (e) {
        await store.dispatch(`user/${UserActions.Logout}`);
        next({name: 'login'});
    }
    const isAuthenticated = store.getters[`user/${UserGetters.IsAuthenticated}`];

    const unprotectedRoutes = [
        'login',
        'register',
        'forgot_password',
        'reset_password',
        'reset_password_success',
        '404',
    ];

    let title = 'Stay Informed App';
    if (isAuthenticated) {
        let appType = store.getters[`user/${UserGetters.AppType}`];

        if (_to.meta.dynamicTitle) {
            _to.meta.title = _to.meta.expression(appType);
        }

        const accountConfiguration = store.getters[`user/${UserGetters.AccountConfiguration}`];
        if (accountConfiguration.groupChatActive || accountConfiguration.individualChatActive) {
            const unreadChatsCount = store.getters[`global/${GlobalGetters.UnreadChatsCount}`];
            if (unreadChatsCount === null) {
                store.dispatch(`chat_app/user/${ChatUserActions.ShowMe}`).then((data) => {
                    return store.dispatch(`global/${GlobalActions.UpdateUnreadChatsCount}`, data.unread_chats_count);
                })
            }
        } else if (_to.path.startsWith('/chat')) {
            next({name: 'dashboard'});
        }

        if (
            appType === AppType.Teams &&
            [
                "members",
                "member.show",
                "member.show.absent_entries"
            ].includes(_to.name)
        ) {
            next({name: "dashboard"});
        }

        setIcons();
    } else {
        setIcons();
    }
    document.title = title;

    if (unprotectedRoutes.includes(_to.name) && isAuthenticated && _to.name !== '404') {
        next({name: 'dashboard'});
    }
    if (!unprotectedRoutes.includes(_to.name) && !isAuthenticated) {

        if (Object.prototype.hasOwnProperty.call(_to.query, 'association')) {
            store.dispatch(`user/${UserActions.UpdatePreSelectedAssociationId}`, _to.query.association);
        }

        if (!isAuthenticated) {
            next({
                name: 'login',
                query: {
                    redirectTo: _to.path
                }
            });
        }
    } else {
        if (Object.prototype.hasOwnProperty.call(_to.query, 'association')) {
            const associationId = store.getters[`user/${UserGetters.AssociationId}`];
            const newAssociationId = _to.query.association;
            delete _to.query.association;
            if (newAssociationId !== associationId) {
                await store.dispatch(`association/${AssociationsActions.ChangeAssociation}`, newAssociationId);
                const associationName = store.getters[`user/${UserGetters.AssociationName}`];

                Vue.toasted.info(i18next.t('associationRedirect', {association_name: associationName}));
            }
            return next(_to);
        }

        next();
    }
});

export default router;
