<template>
  <v-col
      v-if="items.length > 1"
      cols="12"
      md="3"
      :class="$vuetify.rtl ? 'ml-md-auto border-left' : 'mr-md-auto border-right'"
  >
    <v-tabs
        optional
        vertical
        hide-slider
        class="d-none d-md-block"
        v-sticky
        :sticky-z-index="2"
        sticky-offset="{top: 70}"
    >
      <template v-for="({to, label}, index) in items">
        <v-tab
            :key="index"
            active-class="NewsSkyBlue SystemCTA--text font-weight-medium"
            exact
            :to="{name: to}"
            class="text-body-1 text-none"
        >
          <div class="text-left">
            {{ $t(label) }}
          </div>

          <v-icon
              :class="$vuetify.rtl ? 'mr-auto' : 'ml-auto'"
              size="20"
              :right="!$vuetify.rtl"
              :left="$vuetify.rtl"
          >
            {{ $vuetify.rtl ? 'mdi-chevron-left' : 'mdi-chevron-right' }}
          </v-icon>
        </v-tab>

        <v-divider :key="`divider-${index}`"/>
      </template>
    </v-tabs>

    <v-menu
        v-if="$vuetify.breakpoint.smAndDown"
        min-width="100%"
        tile
        offset-y
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
            height="56"
            :ripple="false"
            class="justify-start NewsSkyBlue"
            color="NewsSkyBlue"
            depressed
            text
            block
            v-bind="attrs"
            v-on="on"
        >
          <span class="SystemCTA--text text-subtitle-2 text-capitalize">
            {{ $t(current.label) }} <v-icon>mdi-chevron-down</v-icon>
          </span>
        </v-btn>
      </template>
      <v-list class="py-0" tile elevation="0">
        <template v-for="({to, label}, index) in mobileItems">
          <v-divider
              v-if="index < mobileItems.length"
              :key="index"
          />
          <v-list-item :key="label" :to="{name: to}" exact>
            <v-list-item-title class="SystemCTA--text text-body-1">{{ $t(label) }}</v-list-item-title>
          </v-list-item>
        </template>
      </v-list>
    </v-menu>
  </v-col>
</template>

<script>
import {mapGetters} from "vuex";
import {MemberGetters} from "@/store/member";
import {UserGetters} from "@/store/user";

export default {
  name: "TabsList",

  async mounted() {
    const {showMembers} = this.accountConfiguration;

    if (!showMembers) {
      return this.$router.push('/404')
    }
  },

  computed: {
    ...mapGetters({
      member: `member/${MemberGetters.Member}`,
      accountConfiguration: `user/${UserGetters.AccountConfiguration}`,
    }),

    showAbsentButton() {
      const {config: {sicklist}} = this.member;

      return sicklist;
    },

    showPickupTimes() {
      const {config: {pickupinfo}} = this.member;

      return pickupinfo;
    },

    showLunch() {
      const {config: {lunchinfo}} = this.member;

      return lunchinfo;
    },

    showAdditionalContacts() {
      const {config: {additionalcontacts}} = this.member;

      return additionalcontacts;
    },

    current() {
      const {name} = this.$route;

      return this.items.find(item => item.to === name);
    },

    items() {
      const items = [
        {to: 'member.show', label: 'profileInfo', condition: true},
        {to: 'member.show.absent_entries', label: 'absenceEntriesTitle', condition: this.showAbsentButton},
        {
          to: 'member.show.pickup_times_lunch',
          label: (this.showPickupTimes && this.showLunch) ? 'memberDayHeading' : (this.showPickupTimes) ? 'memberPickupHeading' : 'memberLunch',
          condition: this.showPickupTimes || this.showLunch
        },
        {
          to: 'member.show.additional_contacts',
          label: 'membersAdditionalContacts',
          condition: this.showAdditionalContacts
        }
      ];

      return items.filter(item => item.condition).map(item => {
        return {to: item.to, label: item.label};
      });
    },

    mobileItems() {
      const {name} = this.$route;

      return this.items.filter(item => item.to !== name);
    }
  },

  data() {
    return {
      selected: null,
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~vuetify/src/styles/settings/_variables';

.v-btn {
  &.justify-start {
    position: fixed;
    top: 66px;
    left: 0;
    right: 0;
    z-index: 3;
  }
}

.col-12 {
  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    border-right: none;
    border-left: none;
  }
}

.v-tabs {
  ::v-deep .v-tab:not(.v-tab--active) {
    color: var(--v-Text01-base) !important;

    i {
      visibility: hidden;
    }
  }
}

.v-menu {
  ::v-deep .v-menu__content {
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1);

    .v-list-item {
      min-height: 56px;
    }
  }
}

.v-btn {
  &[aria-expanded="true"] {
    ::v-deep .v-icon {
      transform: rotate(-180deg);
    }
  }

  ::v-deep .v-icon {
    transition: .3s cubic-bezier(.25, .8, .5, 1), visibility 0s;
  }
}
</style>