<template>
  <div v-if="isLunchActive || isPickupActive">
      <v-card
          v-for="(weekday, index) in weekdays"
          :key="index"
          :class="{ 'today-card': isToday(weekday) }"
          class="mb-3"
          color="SystemCTA"
          tile
          outlined
      >
        <v-list class="pt-3 px-2">
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title class="Text01--text text-subtitle-1 d-flex justify-space-between align-center">
                <div>
                  {{ $t(weekday) }}
                </div>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item v-if="pickupTime(weekday)">
            <v-list-item-avatar size="44">
              <v-icon size="24" color="SystemCTA">mdi-clock</v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-subtitle class="Text02--text">{{ $t('memberPickupTime') }}</v-list-item-subtitle>
              <v-list-item-title class="Text01--text text-subtitle-2 d-flex align-center">
                {{ pickupTime(weekday)|date($t('normalTimeFormat')) }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-divider v-if="isLunchActive && pickupTime(weekday)" class="mx-4"/>

          <v-list-item v-if="isLunchActive">
            <v-list-item-avatar size="44">
              <v-icon size="24" color="SystemCTA" style="border-radius: unset;">$fast_food_fill</v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-subtitle class="Text02--text">{{ $t('memberLunch') }}</v-list-item-subtitle>
              <v-list-item-title class="Text01--text text-subtitle-2 d-flex align-center">
                {{ formatLunchRequired(isLunchRequired(weekday)) }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card>
  </div>
</template>

<script>
import moment from 'moment';
import pickupLunchMixin from "@/mixins/pickupLunchMixin";

export default {
  name: "PickupTimesLunchDefault",

  mixins: [pickupLunchMixin],

  data() {
    return {
      weekdays: ['monday', 'tuesday', 'wednesday', 'thursday', 'friday'],
    }
  },

  props: {
    detail: {
      type: Object,
      required: true
    },
    isLunchActive: {
      type: Boolean,
      default: false,
    },
    isPickupActive: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    isToday(weekday) {
      return moment().locale('en').format('dddd').toLowerCase() === weekday;
    },

    isLunchRequired(weekday) {
        return this.detail[`lunch_required_${weekday}`];
    },

    pickupTime(weekday) {
      return this.isPickupActive ? this.detail[`standard_pickup_time_${weekday}`] ?? false : false;
    }
  },
}
</script>

<style lang="scss" scoped>
.today-card {
  border-left-width: thick;
}
.v-avatar {
  border: 1px solid var(--v-GreyLine-base);
}
.icon-container {
  border-radius: 50%;
  transition: background-color 0.3s ease;
  cursor: pointer;
  padding: 8px;
}
</style>